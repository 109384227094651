import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import Layout from "./Layout";
import { ProjectItem } from "./ProjectItem";
import styled from "styled-components";
import { Menu } from "./Menu/Menu";
import { Navbar } from "./Navbar";

const FiltersWrapper = styled.div`
  height: 70px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 40px 70px 20px 70px;
  display: flex;
  width: calc(100% - 140px);

  img {
    width: 30px;
    height: 30px;
    position: relative;
    right: -40px;
    top: -4px;
  }

  @media (max-width: 1279px) {
    display: grid;
    grid-template-columns: 1fr;
    height: 260px;
    grid-gap: 10px;
  }

  @media (max-width: 700px) {
    margin: 40px 30px 20px 30px;
  }
`;
const Wrapper = styled.div`
  padding-bottom: 100px;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
`;

const FilterItemWrapper = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-left: 20px;
  font-weight: bold;
  color: black;

  @media (max-width: 700px) {
    margin-left: 0;
  }
`;

const FilterItem = ({ label, filter, onClick }) => {
  return (
    <FilterItemWrapper onClick={() => onClick(filter)}>
      {label}
    </FilterItemWrapper>
  );
};

const ProjectsWrapper = styled.div`
  display: grid;
  margin: 0 70px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 1279px) {
    grid-template-columns: 1fr;
  }
  
  @media (max-width: 700px) {
    margin: 0 30px;
  }
`;

export const ProjectsRoll = ({ data }) => {
  const { edges: projectsData } = data.allMarkdownRemark;
  const [filter, setFilter] = useState("");

  const [language, setLanguage] = useState("pl");

  useEffect(() => {
    setLanguage(localStorage.getItem("language") || "pl");
  }, []);

  const handleLanguageClick = () => {
    if (language === "pl") {
      setLanguage("eng");
      localStorage.setItem("language", "eng");
    } else {
      setLanguage("pl");
      localStorage.setItem("language", "pl");
    }
  };

  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  useEffect(() => {
    const mappedProjects = projectsData.map((item) => ({
      slug: item.node.fields.slug,
      ...item.node.frontmatter,
    }));
    setProjects(mappedProjects);
    setFilteredProjects(mappedProjects);
  }, [projectsData]);
  //const [projects, setProjects] = useState([]);
  const handleFilterClick = (newFilter) => {
    if (filter === newFilter) {
      setFilter("");
      setFilteredProjects(projects);
      return;
    }
    setFilter(newFilter);
    setFilteredProjects(projects.filter((item) => item[newFilter]));
  };

  const texts = {
    blokInterior_pl: "blok interior",
    blokInterior_eng: "blok interior",
    urban_pl: "urbanistyka",
    urban_eng: "urbanism",
    architecture_eng: "architecture",
    architecture_pl: "architektura",
    housing_eng: "residential",
    housing_pl: "mieszkaniowe",
    service_eng: "commercial",
    service_pl: "usługowe",
    concept_eng: "concepts",
    concept_pl: "koncepcje",
  };

  const translate = (key) => {
    return `${key}_${language}`;
  };
  return (
    <Layout>
      <>
        <Menu
          language={language === "pl" ? "eng" : "pl"}
          handleLanguageClick={handleLanguageClick}
        />
        <Navbar />
        <Wrapper>
          <FiltersWrapper>
            <FilterItem
              label={texts[translate("blokInterior")]}
              filter="BLOK_INTERIOR"
              onClick={handleFilterClick}
            />
            <FilterItem
              label={texts[translate("urban")]}
              filter="URBAN"
              onClick={handleFilterClick}
            />
            <FilterItem
              label={texts[translate("architecture")]}
              filter="ARCHITECTURE"
              onClick={handleFilterClick}
            />
            <FilterItem
              label={texts[translate("housing")]}
              filter="LIVING"
              onClick={handleFilterClick}
            />
            <FilterItem
              label={texts[translate("service")]}
              filter="SERVICE"
              onClick={handleFilterClick}
            />
            <FilterItem
              label={texts[translate("concept")]}
              filter="CONCEPT"
              onClick={handleFilterClick}
            />
          </FiltersWrapper>

          <ProjectsWrapper>
            {filteredProjects &&
              filteredProjects
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((i) => <ProjectItem key={i.title} data={i} />)}
          </ProjectsWrapper>
        </Wrapper>
      </>
    </Layout>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ProjectsRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "project-view" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title_pl
                BLOK_INTERIOR
                URBAN
                ARCHITECTURE
                LIVING
                SERVICE
                CONCEPT
                order
                coverImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ProjectsRoll data={data} count={count} />}
  />
);
