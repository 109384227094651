import React from "react";
import Layout from "../../components/Layout";
import ProjectsRoll from "../../components/ProjectsRoll";

const ProjectsPage = () => {

    return (
        <Layout>
          <ProjectsRoll/>
        </Layout>
    );
};

export default ProjectsPage;
