import React from "react";
import styled from "styled-components";
import Link from "gatsby-link";

const StyledLink = styled(Link)`
  text-decoration: none;
  overflow: hidden;
`;

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  position: relative;
  
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
  }
`;
const Text = styled.div`
  padding: 0 0 56.25% 0;
  color: white;
  font-size: 42px;
  opacity: 0;
  font-weight: 800;
  padding-left: 5px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: inherit;
    transition: background 0.9s ease-in-out;
    z-index: 200;
  
  :hover {
    opacity: 1;
    background: linear-gradient(90deg, rgba(29, 29, 27, 0.5018382352941176) 100%, rgba(29, 29, 27, 0.5102415966386555) 100%);
  }
  
  @media (max-width: 700px) {
    font-size: 24px;
  }
`;

export const ProjectItem = ({ data }) => {
  const image = data.coverImage;
  return (
    <StyledLink to={data.slug}>
      <Wrapper>
          <div className="overlay" />
          <img src={!!image?.childImageSharp ? image?.childImageSharp.fluid.src : image}/>
          <Text>{data.title_pl}</Text>
      </Wrapper>
    </StyledLink>
  );
};
